import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-86fed366"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'storeHeader',
  props: {
    title: {
      type: String
    },
    edit: {
      type: Boolean
    }
  },
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    const data = reactive({
      editString: '编辑'
    });
    const goBack = () => {
      router.back();
    };
    const handleEdit = () => {
      store.commit('changeDelete');
      if (store.state.isDelete) {
        data.editString = '编辑';
      } else {
        data.editString = '完成';
        console.log(data.editString);
      }
    };
    const init = () => {
      store.state.isDelete = true;
      data.editString = '编辑';
    };
    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_icon, {
        name: "arrow-left",
        class: "icon",
        onClick: goBack
      }), _createElementVNode("div", null, _toDisplayString(__props.title), 1), __props.edit ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "edit",
        onClick: handleEdit
      }, _toDisplayString(data.editString), 1)) : _createCommentVNode("", true)]);
    };
  }
};